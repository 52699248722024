import OutputHelper from './OutputHelper';

export default {
  // Lastausmitte a
  getLoadEccentricityA(b = 0, t = 0, alphaATotal = 0, factor = 0) {
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);
    const alphaATotalCasted = OutputHelper.convertToFloat(alphaATotal, 3);
    const factorCasted = OutputHelper.convertToInteger(factor);
    return (factorCasted * (bCasted ** 2) * alphaATotalCasted / (2 * tCasted)) / 1000;
  },
  // Lastausmitte b
  getLoadEccentricityB(a = 0, t = 0, alphaBTotal = 0, factor = 0) {
    const aCasted = OutputHelper.convertToInteger(a);
    const tCasted = OutputHelper.convertToInteger(t);
    const alphaBTotalCasted = OutputHelper.convertToFloat(alphaBTotal, 3);
    const factorCasted = OutputHelper.convertToInteger(factor);
    return (factorCasted * (aCasted ** 2) * alphaBTotalCasted / (2 * tCasted)) / 1000;
  },
  // Lastausmitte Total
  getLoadEccentricityTotal(a = 0, b = 0, t = 0, alphaATotal = 0, alphaBTotal = 0, factor = 0) {
    const loadEccentricityA = this.getLoadEccentricityA(a, t, alphaBTotal, factor);
    const loadEccentricityB = this.getLoadEccentricityB(b, t, alphaATotal, factor);

    return ((loadEccentricityA ** 2) + (loadEccentricityB ** 2)) ** 0.5;
  },
};
