<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineBearingDimension') }}</h3>
        <div class="row">
          <!-- Lagerbeite -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingWidth"
            >
              <template slot="label">
                {{ $t('bearings.bearingWidth') }} <span class="text-muted">a</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingWidth"
                  v-model="bearingWidth"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerlänge -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingLength"
            >
              <template slot="label">
                {{ $t('bearings.bearingLength') }} <span class="text-muted">b</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingLength"
                  v-model="bearingLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerdicke -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingThickness"
            >
              <template slot="label">
                {{ $t('bearings.bearingThickness') }} <span class="text-muted">t</span>
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="bearingThickness"
                  :options="bearingThicknessOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineEffects') }}</h3>
        <div class="row">
          <!-- Kontaktfläche -->
          <div class="col-sm-6">
            <b-form-group
              label-for="typeContactSurface"
            >
              <template slot="label">
                {{ $t('bearings.typeContactSurface') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="typeContactSurface"
                  v-model="typeContactSurface"
                  :options="typeContactSurfaceSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Winkelverdrehung a -->
          <div class="col-lg-6">
            <b-form-group
              label-for="twistingA"
            >
              <template slot="label">
                {{ $t('eccentricity.radTwisting') }}
                <span class="text-muted">&alpha; <sub class="text-muted">a, d</sub></span>
                <b-button
                  id="popover-rad-twisting-a"
                  variant="default"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-rad-twisting-a"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('eccentricity.radTwistingATooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group>
                <FormInputDecimal
                  id="twistingA"
                  v-model="twistingA"
                  decimal-places="3"
                />
                <template v-slot:append>
                  <b-input-group-text>&permil;</b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Winkelverdrehung b -->
          <div class="col-lg-6">
            <b-form-group
              label-for="twistingB"
            >
              <template slot="label">
                {{ $t('eccentricity.radTwisting') }}
                <span class="text-muted">&alpha; <sub>b, d</sub></span>
                <b-button
                  id="popover-rad-twisting-b"
                  variant="default"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-rad-twisting-b"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('eccentricity.radTwistingBTooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text>&permil;</b-input-group-text>
                </template>
                <FormInputDecimal
                  id="twistingB"
                  v-model="twistingB"
                  decimal-places="3"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <!-- Checkbox Verdrehungszuschlag -->
        <div>
          <b-form-checkbox
            id="withTwistSurcharge"
            v-model="twistingWithSurcharge"
            name="withTwistSurcharge"
          >
            {{ $t('bearings.withTwistSurcharge') }}
            <b-button
              id="popover-twist-surcharge"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-twist-surcharge"
              triggers="hover"
              placement="top"
            >
              <p>
                {{ $t('bearings.withTwistSurchargeTooltip1') }}
                <strong>
                  {{ $t('bearings.withTwistSurchargeTooltip2') }}
                </strong>
              </p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </b-form-checkbox>
        </div>
        <!-- Verdrehungszuschlag -->
        <div
          v-if="twistingWithSurcharge"
          class="mt-2"
        >
          <div class="row">
            <!-- Verdrehungszuschlag Schiefwinklig -->
            <div class="col-md-6">
              {{ $t('bearings.twistingSurcharge') }}
              <span class="text-muted">
                &alpha; <sub>{{ $t('bearings.twistingSurchargeAskew') }} </sub>
              </span>
              <span class="text-muted">
                {{ twistingSurchargeAskew | numeralFormat('0.0') }} &permil;
              </span>
            </div>
            <!-- Verdrehungszuschlag Unebenheit -->
            <div class="col-md-6">
              {{ $t('bearings.twistingSurcharge') }}
              <span class="text-muted">
                &alpha; <sub>{{ $t('bearings.twistingSurchargeRoughness') }} </sub>
              </span>
              <span class="text-muted">
                {{ twistingSurchargeRoughness | numeralFormat('0.0') }} &permil;
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('eccentricity.headlineTemperature') }}</h3>
        <div class="row">
          <!-- Temperatur -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingThickness"
            >
              <template slot="label">
                {{ $t('eccentricity.temperatureBearing') }} <span class="text-muted">&#8451;</span>
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="temperature"
                  :options="temperatureSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <h3>{{ $t('bearings.headlineKeyFigures') }}</h3>
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
          <tr>
            <td>
              {{ $t('eccentricity.radTwistingTotal') }}
              <span class="text-muted">
                  &alpha; <sub class="text-muted">a, {{ $t('general.total') }}</sub>
                </span>
              <b-button
                id="popover-rad-twisting-total-a"
                variant="link"
              >
                <font-awesome-icon icon="question-circle" />
              </b-button>
              <b-popover
                target="popover-rad-twisting-total-a"
                triggers="hover"
              >
                <p>{{ $t('eccentricity.radTwistingTotalATooltip') }}</p>
                <p v-html="$t('general.tooltipAdditionalInfo')"></p>
              </b-popover>
            </td>
            <td class="text-right">
              {{ twistingTotal.totalA | numeralFormat('0.00') }} &permil;
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('eccentricity.radTwistingTotal') }}
              <span class="text-muted">
                  &alpha; <sub class="text-muted">b, {{ $t('general.total') }}</sub>
                </span>
              <b-button
                id="popover-rad-twisting-total-b"
                variant="link"
              >
                <font-awesome-icon icon="question-circle" />
              </b-button>
              <b-popover
                target="popover-rad-twisting-total-b"
                triggers="hover"
              >
                <p>{{ $t('eccentricity.radTwistingTotalBTooltip') }}</p>
                <p v-html="$t('general.tooltipAdditionalInfo')"></p>
              </b-popover>
            </td>
            <td class="text-right">
              {{ twistingTotal.totalB | numeralFormat('0.00') }} &permil;
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('eccentricity.loadEccentricityA') }}
              <span class="text-muted">e <sub>a, d</sub></span>
            </td>
            <td class="text-right">
              {{ loadEccentricityA | numeralFormat('0.00') }} mm
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('eccentricity.loadEccentricityB') }}
              <span class="text-muted">e <sub>b, d</sub></span>
            </td>
            <td class="text-right">
              {{ loadEccentricityB | numeralFormat('0.00') }} mm
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('eccentricity.loadEccentricityTotal') }}
              <span class="text-muted">e</span>
            </td>
            <td class="text-right">
              {{ loadEccentricityTotal | numeralFormat('0.00') }} mm
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h3>Schemata</h3>
      <div id="eccentricity_chart">
        <svg
          :width="svgWidth"
          :height="svgHeight"
        >
          <!-- X-Achse -->
          <g
            class="x-axis"
            :transform="`translate(0,${svgHeight - svgMargin.top - svgMargin.bottom})`"
          ></g>
          <text
            :transform="`translate(0, ${svgMargin.top})`"
            font-size="11"
          >Lagerseite a</text>
          <!-- Y-Achse -->
          <g
            class="y-axis"
            :transform="`translate(${svgMargin.left},0)`"
          ></g>
          <text
            :transform="`translate(${svgWidth - 70} ,${svgHeight - 10})`"
            font-size="11"
          >
            Lagerseite b
          </text>

          <!-- Rechteck -->
          <rect
            :x="svgRectX"
            :y="svgRectY"
            :width="svgRectWidth"
            :height="svgRectHeight"
            stroke="#F78222"
            fill="rgba(247, 130, 34, 0.1)"
          >
          </rect>

          <!-- Lagermitte -->
          <circle
            :cx="svgRectCenterX"
            :cy="svgRectCenterY"
            fill="rgba(0,0,0,0.4)"
            r="3"
          >
          </circle>

          <!-- Lagerausmitte (Rotation paraallel zu a) -->
          <g v-if="loadEccentricityA > 0">
            <circle
              r="3"
              :cx="svgEccentricityA"
              :cy="svgRectCenterY"
              :fill="loadEccentricityB === 0 ? svgEccentricityColor : svgEccentricityAColor"
            ></circle>
            <text
              :transform="`translate(${svgEccentricityA + 5} ,${svgRectCenterY - 5})`"
              font-size="11"
            >
              {{ loadEccentricityB === 0 ? svgEccentricityLabel : svgEccentricityALabel }}
            </text>
            <line
              :x1="svgRectCenterX"
              :y1="svgRectCenterY"
              :x2="svgEccentricityA"
              :y2="svgRectCenterY"
              :stroke="loadEccentricityB === 0 ? svgEccentricityColor : svgEccentricityAColor"
            >
            </line>
          </g>

          <!-- Lagerausmitte (Rotation paraallel zu b) -->
          <g v-if="loadEccentricityB > 0">
            <circle
              r="3"
              :cx="svgRectCenterX"
              :cy="svgEccentricityB"
              :fill="loadEccentricityA === 0 ? svgEccentricityColor : svgEccentricityBColor"
            ></circle>
            <text
              :transform="`translate(${svgRectCenterX + 5} ,${svgEccentricityB - 5})`"
              font-size="11"
            >
              {{ loadEccentricityA === 0 ? svgEccentricityLabel : svgEccentricityBLabel }}
            </text>
            <line
              :x1="svgRectCenterX"
              :y1="svgRectCenterY"
              :x2="svgRectCenterX"
              :y2="svgEccentricityB"
              :stroke="loadEccentricityA === 0 ? svgEccentricityColor : svgEccentricityBColor"
            >
            </line>
          </g>

          <!-- Exzentrizität -->
          <g v-if="loadEccentricityA > 0 && loadEccentricityB > 0">
            <circle
              r="3"
              :cx="svgEccentricityA"
              :cy="svgEccentricityB"
              :fill="svgEccentricityColor"
            ></circle>
            <text
              :transform="`translate(${svgEccentricityA + 5} ,${svgEccentricityB - 5})`"
              font-size="11"
            >
              {{ svgEccentricityLabel }}
            </text>
            <line
              :x1="svgRectCenterX"
              :y1="svgRectCenterY"
              :x2="svgEccentricityA"
              :y2="svgEccentricityB"
              :stroke="svgEccentricityColor"
            >
            </line>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import * as d3 from 'd3';
import OutputHelper from '../../helpers/OutputHelper';
import FormInputInteger from '../app/form/FormInputInteger.vue';
import FormInputDecimal from '../app/form/FormInputDecimal.vue';
import CalcToolsEccentricity from '../../helpers/CalcToolsEccentricityHelper';
import DisabledInputs from '../../mixins/DisabledInputs';

export default {
  name: 'ToolsEccentricity',
  components: {
    FormInputInteger,
    FormInputDecimal,
  },
  mixins: [DisabledInputs],
  props: {
    propBearingWidth: {
      type: Number,
      default: 100,
    },
    propBearingLength: {
      type: Number,
      default: 150,
    },
    propBearingThickness: {
      type: Number,
      default: 20,
    },
    propTwistingA: {
      type: Number,
      default: 0,
    },
    propTwistingB: {
      type: Number,
      default: 0,
    },
    propTwistingWithSurcharge: {
      type: Boolean,
      default: false,
    },
    propTypeContactSurface: {
      type: String,
      default: 'steel',
    },
  },
  data() {
    return {
      bearingThicknessOptions: Object.values(this.$dataOptionsHelper.getBearingThicknessOptions()),
      temperatureSelectOptions: Object.values(this.$dataOptionsHelper.getTemperatureOptions()),
      temperatureOptions: this.$dataOptionsHelper.getTemperatureOptions(),
      typeContactSurfaceSelectOptions: Object.values(
        this.$dataOptionsHelper.getTypeContactSurfaceOptions(this.$i18n),
      ),
      temperature: 'gt0',
      twistingSurchargeAskew: 10,
      bearingWidth: this.propBearingWidth,
      bearingLength: this.propBearingLength,
      bearingThickness: this.propBearingThickness,
      twistingA: this.propTwistingA,
      twistingB: this.propTwistingB,
      twistingWithSurcharge: this.propTwistingWithSurcharge,
      typeContactSurface: this.propTypeContactSurface,
      svgMargin: {
        top: 20,
        right: 15,
        bottom: 30,
        left: 30,
      },
      svgWidth: 500,
      svgHeight: 500,
      svgOffsetMax: 100,
      svgOffsetAxis: 20,
      svgEccentricityLabel: 'Exzentrizität',
      svgEccentricityColor: '#c0392b',
      svgEccentricityALabel: 'Vb',
      svgEccentricityAColor: '#8e44ad',
      svgEccentricityBLabel: 'Va',
      svgEccentricityBColor: '#2980b9',
    };
  },
  computed: {
    temperatureFactor: {
      get() {
        return this.temperatureOptions[this.temperature].temperatureFactor;
      },
      set(value) {
        return this.temperatureOptions[value].temperatureFactor;
      },
    },
    twistingSurchargeRoughness() {
      const mainStressedSide = this.$calcHelper.getMainStressedSide(
        this.$outputHelper.convertToNumber(this.twistingA),
        this.$outputHelper.convertToNumber(this.twistingB),
        this.bearingWidth,
        this.bearingLength,
        this.bearingThickness,
      );
      return this.$calcHelper.getSurchargeRoughness(mainStressedSide, this.typeContactSurface);
    },
    twistingTotal() {
      return this.$calcHelper.getTwisting(
        this.$outputHelper.convertToNumber(this.twistingA),
        this.$outputHelper.convertToNumber(this.twistingB),
        this.bearingWidth,
        this.bearingLength,
        this.twistingWithSurcharge,
        this.typeContactSurface,
      );
    },
    loadEccentricityA() {
      return CalcToolsEccentricity.getLoadEccentricityA(
        this.bearingLength,
        this.bearingThickness,
        this.twistingTotal.totalA,
        this.temperatureFactor,
      );
    },
    loadEccentricityB() {
      return CalcToolsEccentricity.getLoadEccentricityB(
        this.bearingWidth,
        this.bearingThickness,
        this.twistingTotal.totalB,
        this.temperatureFactor,
      );
    },
    loadEccentricityTotal() {
      return CalcToolsEccentricity.getLoadEccentricityTotal(
        this.bearingWidth,
        this.bearingLength,
        this.bearingThickness,
        this.twistingTotal.totalA,
        this.twistingTotal.totalB,
        this.temperatureFactor,
      );
    },
    svgMaxY() {
      const svgOffsetMax = OutputHelper.convertToNumber(this.svgOffsetMax);
      const loadEccentricityB = OutputHelper.convertToNumber(this.loadEccentricityB);
      const bearingWidth = OutputHelper.convertToNumber(this.bearingWidth);
      return bearingWidth + loadEccentricityB + svgOffsetMax;
    },
    svgMaxX() {
      const svgOffsetMax = OutputHelper.convertToNumber(this.svgOffsetMax);
      const loadEccentricityA = OutputHelper.convertToNumber(this.loadEccentricityA);
      const bearingLength = OutputHelper.convertToNumber(this.bearingLength);
      return bearingLength + loadEccentricityA + svgOffsetMax;
    },
    svgXScale() {
      return d3.scaleLinear()
        .domain([0, Math.max(this.svgMaxX, this.svgMaxY)])
        .range([this.svgMargin.left, this.svgWidth - this.svgMargin.right]);
    },
    svgYScale() {
      return d3.scaleLinear()
        .domain([0, Math.max(this.svgMaxX, this.svgMaxY)])
        .range(
          [this.svgHeight - this.svgMargin.bottom - this.svgMargin.top, this.svgMargin.bottom],
        );
    },
    // Lagerabmessungen (y startet oben nicht unten)
    svgRectWidth() {
      const svgOffsetAxis = OutputHelper.convertToNumber(this.svgOffsetAxis);
      const bearingLength = OutputHelper.convertToNumber(this.bearingLength);
      return this.svgXScale(svgOffsetAxis + bearingLength)
        - this.svgXScale(svgOffsetAxis);
    },
    // Lagerabmessungen (y startet oben nicht unten)
    svgRectHeight() {
      const svgOffsetAxis = OutputHelper.convertToNumber(this.svgOffsetAxis);
      const bearingWidth = OutputHelper.convertToNumber(this.bearingWidth);
      return this.svgYScale(svgOffsetAxis)
        - this.svgYScale(svgOffsetAxis + bearingWidth);
    },
    svgRectCenterX() {
      const bearingLength = OutputHelper.convertToNumber(this.bearingLength);
      return (this.svgXScale(bearingLength) / 2) + this.svgXScale(this.svgOffsetAxis / 2);
    },
    svgRectCenterY() {
      const bearingWidth = OutputHelper.convertToNumber(this.bearingWidth);
      const svgRectHeight = OutputHelper.convertToNumber(this.svgRectHeight);
      return this.svgYScale(this.svgOffsetAxis + bearingWidth) + (svgRectHeight / 2);
    },
    svgRectX() {
      return this.svgXScale(this.svgOffsetAxis);
    },
    svgRectY() {
      const svgOffsetAxis = OutputHelper.convertToNumber(this.svgOffsetAxis);
      const bearingWidth = OutputHelper.convertToNumber(this.bearingWidth);
      return this.svgYScale(svgOffsetAxis + bearingWidth);
    },
    // Lagerausmitte für Lagerseite a und b
    svgEccentricityA() {
      const svgRectCenterX = OutputHelper.convertToNumber(this.svgRectCenterX);
      const loadEccentricityA = OutputHelper.convertToNumber(this.loadEccentricityA);
      return this.svgXScale(this.svgXScale.invert(svgRectCenterX) + loadEccentricityA);
    },
    // Lagerausmitte für Lagerseite a und b
    svgEccentricityB() {
      const loadEccentricityB = OutputHelper.convertToNumber(this.loadEccentricityB);
      const svgRectCenterY = OutputHelper.convertToNumber(this.svgRectCenterY);
      return this.svgYScale(this.svgYScale.invert(svgRectCenterY) + loadEccentricityB);
    },
  },
  mounted() {
    const chart = document.getElementById('eccentricity_chart');
    if (chart) {
      this.svgWidth = chart.offsetWidth;
      this.svgHeight = chart.offsetWidth;
    }
    this.updateAxis();

    window.addEventListener('resize', debounce(() => {
      if (chart) {
        this.svgWidth = chart.offsetWidth;
        this.svgHeight = chart.offsetWidth;
      }
      this.updateAxis();
    }, 500));


    Object.keys(this._data).forEach((key) => {
      this.$watch(key, () => {
        this.updateAxis();
      });
    });
  },
  methods: {
    updateAxis() {
      // Y-Achse
      d3.select('#eccentricity_chart .y-axis')
        .transition()
        .duration(500)
        .call(d3.axisLeft(this.svgYScale));
      // X-Achse
      d3.select('#eccentricity_chart .x-axis')
        .transition()
        .duration(500)
        .call(d3.axisBottom(this.svgXScale));
    },
  },
};
</script>

<style scoped lang="scss">

</style>
